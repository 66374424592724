<template>
  <div />
</template>

<script>
import { initialAbility } from "@/libs/acl/config";

export default {
  created() {
    this.logout();
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "foravila-login" });
    },
  },
};
</script>
